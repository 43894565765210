<template lang="html">
  <div class="">
    <img src="@/assets/logonewest.svg" alt="" id="logo" />
  </div>

  <div class="loader" v-if="loading"></div>

  <div v-if="!loading">
    <div v-if="nemaPodredjenih">
      <h1>Nema podredjenih za koje biste radili procenu.</h1>
    </div>

    <div v-if="!nemaPodredjenih">
      <div class="header">
        <h1>Formular za procenu radnog učinka - Konačna Procena</h1>
        <h3>(1=Ne Zadovoljava Očekivanja)</h3>
        <h3>(2=Potrebno Poboljšanje)</h3>
        <h3>(3=Zadovoljava Očekivanja)</h3>
        <h3>(4=Iznad Očekivanja)</h3>
      </div>

      <div class="row my-2">
        <div class="col-6">
          <div class="form-group">
            <label class="me-1" for="collegue"
              >Podređeni za koga se vrši konačna procena:</label
            >
            <select
              name="collegue"
              id="collegue"
              v-model="podredjeniName"
              @change="onChangePodredjeni($event)"
            >
              <option v-for="podr in podredjeni" v-bind:key="podr.name">{{
                podr.name
              }}</option>
            </select>
          </div>
        </div>
        <div class="col-6">
          <RouterLink to="performanceEvaluationKonacnaHistory">
            <button class="btn btn-outline-primary  float-end">
              Pregled Istorije Konačne procene
            </button>
          </RouterLink>
        </div>
      </div>

      <div class="celaProcena" v-if="samoProcenaUradjena">
        <div class="kompetence" v-if="kompetenceProc != 0">
          <h3>Kompetence</h3>
          <table id="kompetenceTable">
            <tr>
              <th>Opis:</th>
              <th>Ponder</th>
              <th>Samoprocena</th>
              <th>Konacna Procena</th>
              <th>Rangiranje</th>
            </tr>

            <tr v-for="(komp, i) in kompetence" v-bind:key="i">
              <td>
                {{ komp.name }}
                <button
                  class="definicija"
                  type="button"
                  name="button"
                  @Click="swapBoolKompetence(i)"
                >
                  Definicija
                </button>
                <p v-if="kompetenceBool[i].bool" style="text-align: left;">
                  <span style="white-space: pre-line">
                    {{ kompetenceBool[i].comment }}
                  </span>
                </p>
              </td>
              <td>{{ komp.ponder }}%</td>
              <td v-if="komp.samoProcena != null">{{ komp.samoProcena }}</td>
              <td v-if="komp.samoProcena == null">nema</td>
              <td>
                <select
                  name="type"
                  id="type"
                  v-model="komp.konacnaProcena"
                  @change="updateTable"
                >
                  <option v-for="odg in odgovori" v-bind:key="odg">{{
                    odg
                  }}</option>
                </select>
              </td>
              <td v-if="komp.rangiranje != null">
                {{ komp.rangiranje.toFixed(2) }}
              </td>
              <td v-if="komp.rangiranje == null">0</td>
            </tr>

            <tr>
              <td colspan="4">Ukupno ocena:</td>
              <td>{{ kompetenceOcena.toFixed(2) }}</td>
            </tr>
          </table>
        </div>

        <div class="licni" v-if="licniProc != 0">
          <h3>{{ licniKpiTitle }}</h3>
          <table id="licniTable">
            <tr>
              <th>Opis:</th>
              <th>Ponder</th>
              <th>Samoprocena</th>
              <th>Konacna Procena</th>
              <th>Rangiranje</th>
            </tr>

            <tr v-for="(licni, j) in licniKpi" v-bind:key="j">
              <td>
                {{ licni.name }}
                <button
                  class="definicija"
                  type="button"
                  name="button"
                  @Click="swapBoolLicni(j)"
                >
                  Definicija
                </button>
                <p v-if="licniBool[j].bool" style="text-align: left;">
                  <span style="white-space: pre-line">
                    {{ licniBool[j].comment }}
                  </span>
                </p>
              </td>

              <td>{{ licni.ponder }}%</td>

              <td v-if="licni.samoProcena != null">{{ licni.samoProcena }}</td>

              <td v-if="licni.samoProcena == null">nema</td>
              <td>
                <select
                  name="type"
                  id="type"
                  v-model="licni.konacnaProcena"
                  @change="updateTable"
                >
                  <option v-for="odg in odgovori" v-bind:key="odg">{{
                    odg
                  }}</option>
                </select>
              </td>
              <td v-if="licni.rangiranje != null">
                {{ licni.rangiranje.toFixed(2) }}
              </td>
              <td v-if="licni.rangiranje == null">0</td>
            </tr>

            <tr>
              <td colspan="4">Ukupno ocena:</td>
              <td>{{ licniOcena.toFixed(2) }}</td>
            </tr>
          </table>
        </div>

        <div class="kompanijski mb-2" v-if="kompanijskiProc != 0">
          <h3>Kompanijski KPI</h3>
          <table id="kompanijskiTable">
            <tr>
              <th>Opis:</th>
              <th>Ponder</th>
              <th>Samoprocena</th>
              <th>Konacna Procena</th>
              <th>Rangiranje</th>
            </tr>

            <tr v-for="(kompa, k) in kompanijskiKpi" v-bind:key="k">
              <td>
                {{ kompa.name }}
                <button
                  class="definicija"
                  type="button"
                  name="button"
                  @Click="swapBoolKompanijski(k)"
                >
                  Definicija
                </button>
                <p v-if="kompanijskiBool[k].bool" style="text-align: left;">
                  <span style="white-space: pre-line">
                    {{ kompanijskiBool[k].comment }}
                  </span>
                </p>
              </td>
              <td>{{ kompa.ponder }}%</td>
              <td v-if="kompa.samoProcena != null">{{ kompa.samoProcena }}</td>
              <td v-if="kompa.samoProcena == null">nema</td>
              <td>
                <select
                  name="type"
                  id="type"
                  v-model="kompa.konacnaProcena"
                  @change="updateTable"
                >
                  <option v-for="odg in odgovori" v-bind:key="odg">{{
                    odg
                  }}</option>
                </select>
              </td>
              <td v-if="kompa.rangiranje != null">
                {{ kompa.rangiranje.toFixed(2) }}
              </td>
              <td v-if="kompa.rangiranje == null">0</td>
            </tr>

            <tr>
              <td colspan="4">Ukupno ocena:</td>
              <td>{{ kompanijskiOcena.toFixed(2) }}</td>
            </tr>
          </table>
        </div>

        <div class="rezultat1" v-if="ukupno < 0.749">
          <h3 class="ukupno">
            Ukupan učinak: {{ ((ukupno * 10000 + 1) / 10000).toFixed(2) }}
          </h3>
          <p>
            Rezultati loši, potrebno unapređenje za vršenje trenutne uloge ili
            prelazak na drugo radno mesto
          </p>
        </div>
        <div class="rezultat2" v-if="ukupno >= 0.749 && ukupno < 0.995">
          <h3 class="ukupno">
            Ukupan učinak: {{ ((ukupno * 10000 + 1) / 10000).toFixed(2) }}
          </h3>
          <p>Rezultati dobri, ali neophodan razvoj za vršenje trenutne uloge</p>
        </div>
        <div class="rezultat3" v-if="ukupno >= 0.995 && ukupno < 1.175">
          <h3 class="ukupno">
            Ukupan učinak: {{ ((ukupno * 10000 + 1) / 10000).toFixed(2) }}
          </h3>
          <p>Apsolutno ostvarenje rezultata u trenutnoj ulozi</p>
        </div>
        <div class="rezultat4" v-if="ukupno >= 1.175">
          <h3 class="ukupno">
            Ukupan učinak: {{ ((ukupno * 10000 + 1) / 10000).toFixed(2) }}
          </h3>
          <p>Prebačaj rezultata u trenutnoj ulozi</p>
        </div>

        <div class="komentar">
          <h4>Komentar Samoprocena</h4>
          <p style="text-align: left;">
            <span style="white-space: pre-line">
              {{ komentarSamoProcena }}
            </span>
          </p>
          <!-- <input
            class="form-control"
            type="text"
            placeholder=""
            name="komentar"
            v-model="komentarSamoProcena"
            id="komentar"
            > -->
        </div>
        <div class="komentar">
          <h4>Komentar KonacnaProcena</h4>
          <textarea
            class="form-control"
            id="exampleFormControlTextarea1"
            rows="10"
            v-model="komentar"
          ></textarea>
        </div>

        <div class="w-100 mb-5 mt-1">
          <button class="btn btn-bg btn-primary w-50" @click="submit">
            Submit
          </button>
          <RouterLink to="/profile">
            <button class="btn btn-bg btn-primary text-white w-50">
              Povratak na profil
            </button>
          </RouterLink>
        </div>
      </div>

      <div class="" v-if="!samoProcenaUradjena">
        <h2>Samoprocena nije urađena.</h2>
      </div>
    </div>
  </div>

  <div
    v-if="this.savedSuccessfully"
    class="bg-success p-3 rounded mb-3 text-white text-center fw-bold fixed-top py-5 "
  >
    Promene uspešno snimljene!
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { RouterLink } from "vue-router";
import { mapActions, mapGetters } from "vuex";

export default {
  computed: mapGetters(["user", "users", "companys", "usersSameCompany"]),
  data() {
    return {
      companyKpiConnector: 0,
      odgovori: [1, 2, 3, 4],
      komentar: "",
      kompetence: [],
      licniKpi: [],
      kompanijskiKpi: [],
      ukupno: 0,
      evaluatedUser: "",
      podredjeni: [],
      podredjeniName: "",
      podredjeniCeo: "",
      kompetenceOcena: 0,
      licniOcena: 0,
      kompanijskiOcena: 0,
      kompanijskiProc: 0,
      licniProc: 0,
      kompetenceProc: 0,
      kompetenceBool: [],
      kompanijskiBool: [],
      licniBool: [],
      lastEval: 0,
      validation: "",
      komentarSamoProcena: "",
      samoProcenaUradjena: false,
      loading: true,
      nemaPodredjenih: true,
      savedSuccessfully: false,
      licniKpiTitle: "Lični KPI",
    };
  },
  methods: {
    ...mapActions([
      "getProfile",
      "getCompanies",
      "getCollegues",
      "setKpiKonacnaProcena",
    ]),
    onChangePodredjeni(event) {
      for (var i = 0; i < this.usersSameCompany.length; i++) {
        if (this.usersSameCompany[i].name == event.target.value) {
          this.podredjeniCeo = this.usersSameCompany[i];
        }
      }
      ///PROVERA DA LI POSTOJI SAMOPROCENA
      let thisCompany;
      for (i = 0; i < this.companys.length; i++) {
        if (this.user.type == this.companys[i].name) {
          thisCompany = this.companys[i];
        }
      }
      this.lastEval = this.podredjeniCeo.performanceEvaluation.length - 1;
      this.companyKpiConnector = this.podredjeniCeo.performanceEvaluation[
        this.lastEval
      ].companyKpiConnector;

      let postojiSamoProcena = true;
      if (
        thisCompany.performanceEvaluation[this.companyKpiConnector]
          .kompanijskiProcenat != 0
      ) {
        if (
          this.podredjeniCeo.performanceEvaluation[this.lastEval].kompanijski
            .length == 0
        ) {
          postojiSamoProcena = false;
        }
      } else if (
        thisCompany.performanceEvaluation[this.companyKpiConnector]
          .kompetenceProcenat != 0
      ) {
        if (
          this.podredjeniCeo.performanceEvaluation[this.lastEval].kompetence
            .length == 0
        ) {
          postojiSamoProcena = false;
        }
      } else if (
        thisCompany.performanceEvaluation[this.companyKpiConnector]
          .licniProcenat != 0
      ) {
        if (
          this.podredjeniCeo.performanceEvaluation[this.lastEval].licniKpi[0]
            .samoProcena == null
        ) {
          postojiSamoProcena = false;
        }
      }
      if (postojiSamoProcena) {
        this.samoProcenaUradjena = true;
      } else {
        this.samoProcenaUradjena = false;
      }
      ///////////
      this.lastEval = this.podredjeniCeo.performanceEvaluation.length - 1;
      this.komentarSamoProcena = this.podredjeniCeo.performanceEvaluation[
        this.lastEval
      ].komentarSamoProcena;
      this.komentar = this.podredjeniCeo.performanceEvaluation[
        this.lastEval
      ].komentar;
      this.kompetence = [];
      for (
        i = 0;
        i <
        this.podredjeniCeo.performanceEvaluation[this.lastEval].kompetence
          .length;
        i++
      ) {
        this.kompetence.push({});
        this.kompetence[
          i
        ].samoProcena = this.podredjeniCeo.performanceEvaluation[
          this.lastEval
        ].kompetence[i].samoProcena;
        this.kompetence[
          i
        ].konacnaProcena = this.podredjeniCeo.performanceEvaluation[
          this.lastEval
        ].kompetence[i].konacnaProcena;
        this.kompetence[
          i
        ].rangiranje = this.podredjeniCeo.performanceEvaluation[
          this.lastEval
        ].kompetence[i].rangiranje;
        this.kompetence[i].name = this.podredjeniCeo.performanceEvaluation[
          this.lastEval
        ].kompetence[i].name;
        this.kompetence[i].ponder = this.podredjeniCeo.performanceEvaluation[
          this.lastEval
        ].kompetence[i].ponder;
      }
      this.kompanijskiKpi = [];
      for (
        i = 0;
        i <
        this.podredjeniCeo.performanceEvaluation[this.lastEval].kompanijski
          .length;
        i++
      ) {
        this.kompanijskiKpi.push({});
        this.kompanijskiKpi[
          i
        ].samoProcena = this.podredjeniCeo.performanceEvaluation[
          this.lastEval
        ].kompanijski[i].samoProcena;
        this.kompanijskiKpi[
          i
        ].konacnaProcena = this.podredjeniCeo.performanceEvaluation[
          this.lastEval
        ].kompanijski[i].konacnaProcena;
        this.kompanijskiKpi[
          i
        ].rangiranje = this.podredjeniCeo.performanceEvaluation[
          this.lastEval
        ].kompanijski[i].rangiranje;
        this.kompanijskiKpi[i].name = this.podredjeniCeo.performanceEvaluation[
          this.lastEval
        ].kompanijski[i].name;
        this.kompanijskiKpi[
          i
        ].ponder = this.podredjeniCeo.performanceEvaluation[
          this.lastEval
        ].kompanijski[i].ponder;
      }

      this.licniKpi = [];
      for (
        i = 0;
        i <
        this.podredjeniCeo.performanceEvaluation[this.lastEval].licniKpi.length;
        i++
      ) {
        this.licniKpi.push(
          this.podredjeniCeo.performanceEvaluation[this.lastEval].licniKpi[i]
        );
      }
      this.updateTable();
    },
    swapBoolKompetence(i) {
      if (this.kompetenceBool[i].bool) {
        this.kompetenceBool[i].bool = false;
      } else {
        this.kompetenceBool[i].bool = true;
      }
    },
    swapBoolKompanijski(i) {
      if (this.kompanijskiBool[i].bool) {
        this.kompanijskiBool[i].bool = false;
      } else {
        this.kompanijskiBool[i].bool = true;
      }
    },
    swapBoolLicni(i) {
      if (this.licniBool[i].bool) {
        this.licniBool[i].bool = false;
      } else {
        this.licniBool[i].bool = true;
      }
    },
    updateTable() {
      //kompetence
      let tempCompany = {};
      for (i = 0; i < this.companys.length; i++) {
        if (this.companys[i].name == this.podredjeniCeo.type) {
          tempCompany = this.companys[i];
        }
      }
      this.kompetenceProc =
        tempCompany.performanceEvaluation[
          this.companyKpiConnector
        ].kompetenceProcenat;
      this.licniProc =
        tempCompany.performanceEvaluation[
          this.companyKpiConnector
        ].licniProcenat;
      this.kompanijskiProc =
        tempCompany.performanceEvaluation[
          this.companyKpiConnector
        ].kompanijskiProcenat;

      this.lastEval = this.podredjeniCeo.performanceEvaluation.length - 1;
      let brojac =
        tempCompany.performanceEvaluation[this.companyKpiConnector].kompanijski
          .length;
      for (i = 0; i < brojac; i++) {
        let komp = {
          bool: false,
          comment:
            tempCompany.performanceEvaluation[this.companyKpiConnector]
              .kompanijski[i].comment,
        };
        this.kompanijskiBool.push(komp);
      }
      brojac =
        tempCompany.performanceEvaluation[this.companyKpiConnector].kompetence
          .length;
      for (i = 0; i < brojac; i++) {
        let komp = {
          bool: false,
          comment:
            tempCompany.performanceEvaluation[this.companyKpiConnector]
              .kompetence[i].comment,
        };
        this.kompetenceBool.push(komp);
      }
      brojac = this.podredjeniCeo.performanceEvaluation[this.lastEval].licniKpi
        .length;
      if (
        this.podredjeniCeo.performanceEvaluation[this.lastEval].licniKpiTitle !=
        undefined
      ) {
        this.licniKpiTitle = this.podredjeniCeo.performanceEvaluation[
          this.lastEval
        ].licniKpiTitle;
      } else {
        this.licniKpiTitle = "Lični KPI";
      }
      for (i = 0; i < brojac; i++) {
        let komp = {
          bool: false,
          comment: this.podredjeniCeo.performanceEvaluation[this.lastEval]
            .licniKpi[i].comment,
        };
        this.licniBool.push(komp);
      }
      let ukupnoKompetence = 0;
      for (var i = 0; i < this.kompetence.length; i++) {
        if (this.kompetence[i].konacnaProcena != null) {
          this.kompetence[i].rangiranje =
            ((this.kompetence[i].konacnaProcena * 0.25 + 0.25) *
              this.kompetence[i].ponder) /
            100;
          ukupnoKompetence += parseFloat(
            this.kompetence[i].rangiranje.toFixed(2)
          );
        }
      }
      this.kompetenceOcena = ukupnoKompetence;
      //licni
      let ukupnoLicni = 0;
      for (i = 0; i < this.licniKpi.length; i++) {
        if (this.licniKpi[i].konacnaProcena != null) {
          this.licniKpi[i].rangiranje =
            ((this.licniKpi[i].konacnaProcena * 0.25 + 0.25) *
              this.licniKpi[i].ponder) /
            100;
          ukupnoLicni += parseFloat(this.licniKpi[i].rangiranje.toFixed(2));
        }
      }
      this.licniOcena = ukupnoLicni;
      //kompanijski
      let ukupnoKompanijski = 0;
      for (i = 0; i < this.kompanijskiKpi.length; i++) {
        if (this.kompanijskiKpi[i].konacnaProcena != null) {
          this.kompanijskiKpi[i].rangiranje =
            ((this.kompanijskiKpi[i].konacnaProcena * 0.25 + 0.25) *
              this.kompanijskiKpi[i].ponder) /
            100;
          ukupnoKompanijski += parseFloat(
            this.kompanijskiKpi[i].rangiranje.toFixed(2)
          );
        }
      }
      this.kompanijskiOcena = ukupnoKompanijski;
      this.ukupno =
        (tempCompany.performanceEvaluation[this.companyKpiConnector]
          .kompetenceProcenat /
          100) *
          ukupnoKompetence +
        (tempCompany.performanceEvaluation[this.companyKpiConnector]
          .licniProcenat /
          100) *
          ukupnoLicni +
        (tempCompany.performanceEvaluation[this.companyKpiConnector]
          .kompanijskiProcenat /
          100) *
          ukupnoKompanijski;
    },
    notificationSuccess() {
      this.savedSuccessfully = true;
      setTimeout(() => (this.savedSuccessfully = false), 5000);
    },
    submit() {
      const d = new Date();
      let day = d.getDate();
      let month = d.getMonth() + 1;
      let year = d.getFullYear();
      let dateText = day + "." + month + "." + year + ".";
      let kpiData = {
        licniKpi: this.licniKpi,
        kompanijski: this.kompanijskiKpi,
        kompetence: this.kompetence,
        userId: this.podredjeniCeo._id,
        komentar: this.komentar,
        lastEval: this.lastEval,
        date: dateText,
      };
      this.setKpiKonacnaProcena(kpiData)
        .then((res) => {
          if (res.data.success) {
            this.notificationSuccess();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    loadKpi(user) {
      console.log(user);
    },
  },
  async created() {
    await this.getProfile();
    await this.getCompanies();
    await this.getCollegues(this.user.type);
    //
    // let thisCompany;
    // for (var i = 0; i < this.companys.length; i++) {
    //   if(this.user.type==this.companys[i].name){
    //     thisCompany=this.companys[i];
    //   }
    // }
    //
    // let brojac = thisCompany.performanceEvaluation[0].kompanijski.length;
    // for ( i = 0; i < brojac; i++) {
    //   let komp={
    //     bool:false,
    //     comment:thisCompany.performanceEvaluation[0].kompanijski[i].comment
    //   }
    //   this.kompanijskiBool.push(komp);
    // }
    //
    //
    // brojac = thisCompany.performanceEvaluation[0].kompetence.length;
    // for ( i = 0; i < brojac; i++) {
    //   let komp={
    //     bool:false,
    //     comment:thisCompany.performanceEvaluation[0].kompetence[i].comment
    //   }
    //   this.kompetenceBool.push(komp);
    // }
    //
    // this.kompetenceProc=thisCompany.performanceEvaluation[0].kompetenceProcenat;
    // this.licniProc=thisCompany.performanceEvaluation[0].licniProcenat;
    // this.kompanijskiProc=thisCompany.performanceEvaluation[0].kompanijskiProcenat;
    //
    // for ( i = 0; i < thisCompany.performanceEvaluation[0].kompetence.length; i++) {
    //   this.kompetence.push(thisCompany.performanceEvaluation[0].kompetence[i]);
    // }
    //
    // for ( i = 0; i < thisCompany.performanceEvaluation[0].kompanijski.length; i++) {
    //   this.kompanijskiKpi.push(thisCompany.performanceEvaluation[0].kompanijski[i]);
    // }
    //
    //
    for (var j = 0; j < this.usersSameCompany.length; j++) {
      if (this.user.name == this.usersSameCompany[j].nadredjeni) {
        this.podredjeni.push(this.usersSameCompany[j]);
      }
    }
    if (this.podredjeni.length > 0) {
      this.nemaPodredjenih = false;
    }
    this.loading = false;
  },
  beforeCreate: function() {
    document.body.className = "other";
  },
  components: { RouterLink },
};
</script>

<style lang="css" scoped>
.rezultat1 {
  background-color: red;
  color: white;
  border-style: solid;
  border-color: black;
  border-width: medium;
  border-radius: 15px;
  padding: 10px;
  text-align: center;
  border-top: 0px;

  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}
.rezultat2 {
  background-color: yellow;
  color: white;
  border-style: solid;
  border-color: black;
  border-width: medium;
  border-radius: 15px;
  padding: 10px;
  text-align: center;
  border-top: 0px;

  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}
.rezultat3 {
  background-color: cyan;
  color: white;
  border-style: solid;
  border-color: black;
  border-width: medium;
  border-radius: 15px;
  padding: 10px;
  text-align: center;
  border-top: 0px;

  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}
.rezultat4 {
  background-color: green;
  color: white;
  border-style: solid;
  border-color: black;
  border-width: medium;
  border-radius: 15px;
  padding: 10px;
  text-align: center;
  border-top: 0px;

  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}
p {
}
.ukupno {
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}
table,
th,
td {
  border: 1px solid black;
  text-align: center;
}
tr:nth-child(even) {
  background-color: #bbbbbb;
}
tr:nth-child(odd) {
  background-color: #ffffff;
}
tr:hover {
  background-color: #afa;
}
table {
  margin: auto;
}
.header {
  background-color: #ffaaaa;
  padding: 15px;
  border-style: solid;
  border-color: black;
  border-width: medium;
  border-radius: 15px;
}
.formular {
  background-color: #ffffaa;
  border-style: solid;
  border-color: black;
  border-width: medium;
  border-top: 0px;
  border-radius: 15px;
  padding: 10px;
}
.komentar {
  background-color: #ffffaa;
  border-style: solid;
  border-color: black;
  border-width: medium;
  border-top: 0px;
  border-radius: 15px;
  padding: 10px;
}
h4 {
  text-align: center;
}
.submitDiv {
  width: 100%;
}
.btn-bg {
  width: 100%;
  background-color: #003366;
}
.validation {
  margin-top: 5px;
  background-color: #00aaaa;
}
.definicija {
  background-color: #ffff88;
  border-style: solid;
  border-color: black;
  border-width: thin;
  border-radius: 5px;
  float: right;
}
.definicija:hover {
  background-color: #dddd66;
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;

  margin: auto;
  margin-top: 100px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
